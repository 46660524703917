[data-v-2555a05d] .el-tabs__item {
  padding: 0 10px;
}
[data-v-2555a05d] .el-tabs__item:nth-last-child(2) {
  padding: 0 30px !important;
}
[data-v-2555a05d] .el-tabs__item:last-child {
  padding-right: 10px !important;
}
.card-header[data-v-2555a05d] {
  height: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.box-card[data-v-2555a05d] {
  width: 30%;
}
.title[data-v-2555a05d] {
  font-size: 16px;
  font-weight: 600;
}
.spbh[data-v-2555a05d] {
  color: var(--themeColor, #17a2b8);
  position: relative;
  float: right;
  top: -60px;
}
.spbh span[data-v-2555a05d] {
  margin-right: 10px;
  margin-left: 10px;
}
.spbh span img[data-v-2555a05d] {
  position: relative;
  top: 7px;
}
.noDate[data-v-2555a05d] {
  text-align: center;
  font-size: 31px;
  font-weight: 500;
  color: #1482f0;
  margin-top: -104px;
}
.rightSpan[data-v-2555a05d] {
  width: 30%;
  margin-right: 10px;
  height: 100%;
  background-color: #eee;
  display: inline-block;
  text-align: center;
  line-height: 50px;
  position: relative;
}
.left_item[data-v-2555a05d] {
  width: 100%;
  margin: 0 auto;
  background: #eee;
  padding: 15px;
  height: 360px;
}
.left_item h5[data-v-2555a05d] {
  margin-top: 20px;
  font-size: 16px;
  color: #000;
  line-height: 32px;
  font-weight: normal;
  padding-left: 30px;
}
.left_item p[data-v-2555a05d] {
  float: left;
  margin-right: 15px;
  width: 46%;
  font-size: 14px;
  color: #333;
  line-height: 32px;
}
.left_item p[data-v-2555a05d]:last-child {
  margin-right: 0;
}
.center_fonts[data-v-2555a05d] {
  width: 100%;
  margin: 0 auto;
  margin-top: 10px;
  padding-left: 30px;
}
.center_fonts .item_font[data-v-2555a05d] {
  width: 100%;
  margin-top: 8px;
}
.center_fonts .item_font p[data-v-2555a05d] {
  float: left;
  width: 60%;
  font-size: 13px;
  color: #333;
  line-height: 24px;
}
.center_fonts .item_font p span[data-v-2555a05d] {
  padding: 0 5px 0 10px;
  color: var(--themeColor, #17a2b8);
}
.center_fonts .item_font h6[data-v-2555a05d] {
  float: left;
  width: 30%;
  font-size: 13px;
  color: var(--themeColor, #17a2b8);
  line-height: 24px;
  cursor: pointer;
}
.btns[data-v-2555a05d] {
  width: 100%;
  margin: 0 auto;
  margin-top: 15px;
}
.btns div[data-v-2555a05d] {
  width: 80%;
  margin: 0 auto;
  line-height: 32px;
  font-size: 14px;
  color: #fff;
  background: var(--themeColor, #17a2b8);
  margin-top: 20px;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
}
.total_amount[data-v-2555a05d] {
  width: 100%;
  margin: 0 auto;
}
.total_amount h5[data-v-2555a05d] {
  font-size: 15px;
  color: #000;
  line-height: 26px;
  font-weight: normal;
}
.total_amount h6[data-v-2555a05d] {
  font-size: 13px;
  margin-top: 5px;
  color: #333;
  line-height: 26px;
  font-weight: normal;
}
.total_amount .bottom_btn[data-v-2555a05d] {
  width: 100%;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
}
.total_amount .bottom_btn label[data-v-2555a05d] {
  line-height: 26px;
  color: #333;
  font-size: 13px;
  font-weight: normal;
}
.total_amount .bottom_btn p[data-v-2555a05d] {
  min-width: 200px;
  line-height: 26px;
  color: #f15a23;
  font-size: 13px;
  font-weight: normal;
}
.bottom_month_amount[data-v-2555a05d] {
  height: 202px;
  width: 100%;
  margin: 0 auto;
  margin-top: 15px;
  border-top: 1px solid #c2c2c2;
}
.bottom_month_amount .top_save[data-v-2555a05d] {
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bottom_month_amount .top_save p[data-v-2555a05d] {
  line-height: 28px;
  color: #333;
  font-size: 13px;
  font-weight: normal;
}
.month_box[data-v-2555a05d] {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  border-right: 1px solid #c2c2c2;
  border-top: 1px solid #c2c2c2;
}
.month_box .month_item[data-v-2555a05d] {
  width: 16.66666%;
  cursor: pointer;
}
.month_box .month_item h4[data-v-2555a05d] {
  width: 100%;
  line-height: 30px;
  height: 30px;
  background: #eee;
  color: #333;
  font-size: 13px;
  text-align: center;
  border-left: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
}
.month_box .month_item input[data-v-2555a05d] {
  width: 100%;
  line-height: 30px;
  height: 30px;
  padding: 0 0px;
  color: #333;
  font-size: 13px;
  text-align: center;
  border-bottom: 1px solid #c2c2c2;
  border-left: 1px solid #c2c2c2;
}
.month_box .month_item div[data-v-2555a05d] {
  width: 100%;
  line-height: 30px;
  height: 30px;
  padding: 0 0px;
  color: #333;
  font-size: 13px;
  text-align: center;
  border-bottom: 1px solid #c2c2c2;
  border-left: 1px solid #c2c2c2;
}
.iconStyle[data-v-2555a05d] {
  font-size: 50px;
  color: #fff;
  position: absolute;
  bottom: 20px;
}