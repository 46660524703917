.top_left[data-v-2555a05d] {
  border: 1px solid #d7d7d7;
  height: 320px;
  padding: 10px 10px;
}
.top_left h5[data-v-2555a05d] {
  font-size: 14px;
  font-weight: 500;
}
.top_left h5[data-v-2555a05d]::before {
  border-left: 3px solid var(--themeColor, #17a2b8);
  content: "";
  display: inline-block;
  height: 13px;
  position: relative;
  margin-right: 5px;
}
.top_left .left_total[data-v-2555a05d] {
  padding: 15px 0;
  color: #333;
  font-size: 13px;
  font-weight: 500;
  margin-left: 10%;
}
.top_left .left_info[data-v-2555a05d] {
  font-size: 12px;
  margin-left: 10%;
  color: #5f5f5f;
}
.top_left .left_info p[data-v-2555a05d] {
  margin-bottom: 5px;
  letter-spacing: 1px;
}
.bill .bill_left[data-v-2555a05d] {
  font-size: 13px;
  font-weight: 500;
  float: left;
  width: 310px;
}
.bill .bill_left .bill_one[data-v-2555a05d] {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #efefef;
}
.bill .bill_left .bill_one[data-v-2555a05d]::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: relative;
  margin-right: 10px;
}
.bill .bill_one[data-v-2555a05d]:last-child {
  border-bottom: none;
}
.bill .bill_right[data-v-2555a05d] {
  float: right;
  width: calc(100% - 310px);
}
.theme_font[data-v-2555a05d] {
  color: var(--themeColor, #17a2b8);
  font-size: 14px;
}
.income_style[data-v-2555a05d] {
  display: flex;
  padding-left: 2px;
  padding-top: 15px;
  font-size: 12px;
}
.income_style .income_font[data-v-2555a05d]::before {
  content: "";
  background: #e6e6e6;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: relative;
  margin-right: 5px;
}
.income_style .income_font[data-v-2555a05d] {
  margin-top: 10px;
}
.bottom_right[data-v-2555a05d] {
  border: 1px solid #d7d7d7;
  padding: 10px;
}
.bottom_right .bottom_icon[data-v-2555a05d] {
  width: 48%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.bottom_right .bottom_icon .each_style[data-v-2555a05d] {
  height: 23%;
  border: 1px solid #efefef;
  position: relative;
}
.bottom_right .bottom_icon .each_size[data-v-2555a05d] {
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bottom_right .bottom_icon .left_icon[data-v-2555a05d] {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 15px;
  text-align: center;
  line-height: 40px;
}
.bottom_right .bottom_icon .left_icon img[data-v-2555a05d] {
  width: 100%;
  height: 100%;
}
.bottom_right .bottom_icon .left_icon .iconfont[data-v-2555a05d] {
  font-size: 20px;
  color: #fff;
}
.bottom_right .bottom_icon .right_title[data-v-2555a05d] {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 500;
  margin-bottom: 3px;
  color: #333;
}
.bottom_right .bottom_icon .right_amount[data-v-2555a05d] {
  font-size: 12px;
  font-weight: 600;
  color: #656565;
}
.right_box[data-v-2555a05d] {
  position: absolute;
  top: 11px;
  right: 10px;
  font-size: 13px;
  color: #999;
}
[data-v-2555a05d] .el-badge__content.is-fixed {
  top: 11px;
  right: 2px;
}
.edit_style[data-v-2555a05d] {
  text-decoration: underline;
  cursor: pointer;
}